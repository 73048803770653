import routerOptions0 from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}