import revive_payload_client_4sVQNw7RlN from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import plugin_J8PkxsSQmi from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt-snackbar/dist/runtime/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt3_plugin_BdfRvWAAOY from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_AOuQ1DYzjk from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_LDumGYo2KH from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_1aypKNZ222 from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import dayjs_pGiXRjcsJO from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/plugins/dayjs.ts";
import sentry_3AyO8nEfhE from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/plugins/sentry.ts";
import laravel_echo_skpHYtYD88 from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/plugins/laravel-echo.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  plugin_J8PkxsSQmi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_AOuQ1DYzjk,
  i18n_yfWm7jX06p,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  dayjs_pGiXRjcsJO,
  sentry_3AyO8nEfhE,
  laravel_echo_skpHYtYD88
]