import { default as updateT2N8kjwRFyMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/[id]/update.vue?macro=true";
import { default as createLYkh7BdTUPMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/create.vue?macro=true";
import { default as indexIDdT4taab7Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/index.vue?macro=true";
import { default as indexu2PGSqSwacMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/[id]/index.vue?macro=true";
import { default as updateH8d9PZUFixMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/[id]/update.vue?macro=true";
import { default as createGTb7K6aTHeMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/create.vue?macro=true";
import { default as indexyZtm1iqLetMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/index.vue?macro=true";
import { default as signinoelA1AtPjcMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/auth/signin.vue?macro=true";
import { default as updateJJCN6Ycop9Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/[id]/update.vue?macro=true";
import { default as create8FrzkG3QJcMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/create.vue?macro=true";
import { default as indexxtWR8OqJaSMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/index.vue?macro=true";
import { default as calls2XVVrk3RySMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/dashboard/calls.vue?macro=true";
import { default as indexefESWf0tTTMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/[id]/index.vue?macro=true";
import { default as updatenWCPXX11SqMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/[id]/update.vue?macro=true";
import { default as createirl4K4wQfxMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/create.vue?macro=true";
import { default as index1tuSD5sXHNMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/index.vue?macro=true";
import { default as updatee0PV8LlOF7Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/[id]/update.vue?macro=true";
import { default as create8c0LjDIx3OMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/create.vue?macro=true";
import { default as indexQilUG86r20Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/index.vue?macro=true";
import { default as updateYCMtrLilkfMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/[id]/update.vue?macro=true";
import { default as createhmYoOGNbqfMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/create.vue?macro=true";
import { default as indexALGmqdwuJxMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/index.vue?macro=true";
import { default as inboxJZ4kDNG3GkMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/inbox.vue?macro=true";
import { default as indexyqIBGJQToqMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/index.vue?macro=true";
import { default as indexTha7ODZrTDMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/[id]/index.vue?macro=true";
import { default as update0UYLDiSLSJMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/[id]/update.vue?macro=true";
import { default as createnvThzFOZSSMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/create.vue?macro=true";
import { default as indexKlZ4cKtKCaMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/index.vue?macro=true";
import { default as indexxKoRSq3Hd6Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile/index.vue?macro=true";
import { default as settingsHFxVacfWAQMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile/settings.vue?macro=true";
import { default as profileI8EZJt5br2Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile.vue?macro=true";
import { default as indexwwdTcMfKzNMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/index.vue?macro=true";
import { default as membersE4GFGQM8GyMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/members.vue?macro=true";
import { default as notificationsgagROoUMkfMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/notifications.vue?macro=true";
import { default as updateVZGjo9fJECMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/[id]/update.vue?macro=true";
import { default as createDxDMi6yJDjMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/create.vue?macro=true";
import { default as indexU1bUdvT9xOMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/index.vue?macro=true";
import { default as testh61wq6mQAAMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/test.vue?macro=true";
import { default as updatewL4qo59xdrMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/[id]/update.vue?macro=true";
import { default as createhE4P9hY0V0Meta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/create.vue?macro=true";
import { default as indexsmYbWxttTuMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/index.vue?macro=true";
import { default as indexoxLiJGNsBYMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/[id]/index.vue?macro=true";
import { default as updatesP9LqS76lmMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/[id]/update.vue?macro=true";
import { default as create7AuD6UTcqSMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/create.vue?macro=true";
import { default as indexK4fRuqJBPZMeta } from "/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/index.vue?macro=true";
export default [
  {
    name: updateT2N8kjwRFyMeta?.name ?? "ads-id-update___ru",
    path: updateT2N8kjwRFyMeta?.path ?? "/ads/:id()/update",
    meta: updateT2N8kjwRFyMeta || {},
    alias: updateT2N8kjwRFyMeta?.alias || [],
    redirect: updateT2N8kjwRFyMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createLYkh7BdTUPMeta?.name ?? "ads-create___ru",
    path: createLYkh7BdTUPMeta?.path ?? "/ads/create",
    meta: createLYkh7BdTUPMeta || {},
    alias: createLYkh7BdTUPMeta?.alias || [],
    redirect: createLYkh7BdTUPMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/create.vue").then(m => m.default || m)
  },
  {
    name: indexIDdT4taab7Meta?.name ?? "ads___ru",
    path: indexIDdT4taab7Meta?.path ?? "/ads",
    meta: indexIDdT4taab7Meta || {},
    alias: indexIDdT4taab7Meta?.alias || [],
    redirect: indexIDdT4taab7Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/ads/index.vue").then(m => m.default || m)
  },
  {
    name: indexu2PGSqSwacMeta?.name ?? "agencies-id___ru",
    path: indexu2PGSqSwacMeta?.path ?? "/agencies/:id()",
    meta: indexu2PGSqSwacMeta || {},
    alias: indexu2PGSqSwacMeta?.alias || [],
    redirect: indexu2PGSqSwacMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updateH8d9PZUFixMeta?.name ?? "agencies-id-update___ru",
    path: updateH8d9PZUFixMeta?.path ?? "/agencies/:id()/update",
    meta: updateH8d9PZUFixMeta || {},
    alias: updateH8d9PZUFixMeta?.alias || [],
    redirect: updateH8d9PZUFixMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createGTb7K6aTHeMeta?.name ?? "agencies-create___ru",
    path: createGTb7K6aTHeMeta?.path ?? "/agencies/create",
    meta: createGTb7K6aTHeMeta || {},
    alias: createGTb7K6aTHeMeta?.alias || [],
    redirect: createGTb7K6aTHeMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/create.vue").then(m => m.default || m)
  },
  {
    name: indexyZtm1iqLetMeta?.name ?? "agencies___ru",
    path: indexyZtm1iqLetMeta?.path ?? "/agencies",
    meta: indexyZtm1iqLetMeta || {},
    alias: indexyZtm1iqLetMeta?.alias || [],
    redirect: indexyZtm1iqLetMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/agencies/index.vue").then(m => m.default || m)
  },
  {
    name: signinoelA1AtPjcMeta?.name ?? "auth-signin___ru",
    path: signinoelA1AtPjcMeta?.path ?? "/auth/signin",
    meta: signinoelA1AtPjcMeta || {},
    alias: signinoelA1AtPjcMeta?.alias || [],
    redirect: signinoelA1AtPjcMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: updateJJCN6Ycop9Meta?.name ?? "banners-id-update___ru",
    path: updateJJCN6Ycop9Meta?.path ?? "/banners/:id()/update",
    meta: updateJJCN6Ycop9Meta || {},
    alias: updateJJCN6Ycop9Meta?.alias || [],
    redirect: updateJJCN6Ycop9Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create8FrzkG3QJcMeta?.name ?? "banners-create___ru",
    path: create8FrzkG3QJcMeta?.path ?? "/banners/create",
    meta: create8FrzkG3QJcMeta || {},
    alias: create8FrzkG3QJcMeta?.alias || [],
    redirect: create8FrzkG3QJcMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/create.vue").then(m => m.default || m)
  },
  {
    name: indexxtWR8OqJaSMeta?.name ?? "banners___ru",
    path: indexxtWR8OqJaSMeta?.path ?? "/banners",
    meta: indexxtWR8OqJaSMeta || {},
    alias: indexxtWR8OqJaSMeta?.alias || [],
    redirect: indexxtWR8OqJaSMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/banners/index.vue").then(m => m.default || m)
  },
  {
    name: calls2XVVrk3RySMeta?.name ?? "dashboard-calls___ru",
    path: calls2XVVrk3RySMeta?.path ?? "/dashboard/calls",
    meta: calls2XVVrk3RySMeta || {},
    alias: calls2XVVrk3RySMeta?.alias || [],
    redirect: calls2XVVrk3RySMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/dashboard/calls.vue").then(m => m.default || m)
  },
  {
    name: indexefESWf0tTTMeta?.name ?? "developers-id___ru",
    path: indexefESWf0tTTMeta?.path ?? "/developers/:id()",
    meta: indexefESWf0tTTMeta || {},
    alias: indexefESWf0tTTMeta?.alias || [],
    redirect: indexefESWf0tTTMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatenWCPXX11SqMeta?.name ?? "developers-id-update___ru",
    path: updatenWCPXX11SqMeta?.path ?? "/developers/:id()/update",
    meta: updatenWCPXX11SqMeta || {},
    alias: updatenWCPXX11SqMeta?.alias || [],
    redirect: updatenWCPXX11SqMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createirl4K4wQfxMeta?.name ?? "developers-create___ru",
    path: createirl4K4wQfxMeta?.path ?? "/developers/create",
    meta: createirl4K4wQfxMeta || {},
    alias: createirl4K4wQfxMeta?.alias || [],
    redirect: createirl4K4wQfxMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/create.vue").then(m => m.default || m)
  },
  {
    name: index1tuSD5sXHNMeta?.name ?? "developers___ru",
    path: index1tuSD5sXHNMeta?.path ?? "/developers",
    meta: index1tuSD5sXHNMeta || {},
    alias: index1tuSD5sXHNMeta?.alias || [],
    redirect: index1tuSD5sXHNMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/developers/index.vue").then(m => m.default || m)
  },
  {
    name: updatee0PV8LlOF7Meta?.name ?? "geo-cities-id-update___ru",
    path: updatee0PV8LlOF7Meta?.path ?? "/geo/cities/:id()/update",
    meta: updatee0PV8LlOF7Meta || {},
    alias: updatee0PV8LlOF7Meta?.alias || [],
    redirect: updatee0PV8LlOF7Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create8c0LjDIx3OMeta?.name ?? "geo-cities-create___ru",
    path: create8c0LjDIx3OMeta?.path ?? "/geo/cities/create",
    meta: create8c0LjDIx3OMeta || {},
    alias: create8c0LjDIx3OMeta?.alias || [],
    redirect: create8c0LjDIx3OMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/create.vue").then(m => m.default || m)
  },
  {
    name: indexQilUG86r20Meta?.name ?? "geo-cities___ru",
    path: indexQilUG86r20Meta?.path ?? "/geo/cities",
    meta: indexQilUG86r20Meta || {},
    alias: indexQilUG86r20Meta?.alias || [],
    redirect: indexQilUG86r20Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/cities/index.vue").then(m => m.default || m)
  },
  {
    name: updateYCMtrLilkfMeta?.name ?? "geo-regions-id-update___ru",
    path: updateYCMtrLilkfMeta?.path ?? "/geo/regions/:id()/update",
    meta: updateYCMtrLilkfMeta || {},
    alias: updateYCMtrLilkfMeta?.alias || [],
    redirect: updateYCMtrLilkfMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createhmYoOGNbqfMeta?.name ?? "geo-regions-create___ru",
    path: createhmYoOGNbqfMeta?.path ?? "/geo/regions/create",
    meta: createhmYoOGNbqfMeta || {},
    alias: createhmYoOGNbqfMeta?.alias || [],
    redirect: createhmYoOGNbqfMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/create.vue").then(m => m.default || m)
  },
  {
    name: indexALGmqdwuJxMeta?.name ?? "geo-regions___ru",
    path: indexALGmqdwuJxMeta?.path ?? "/geo/regions",
    meta: indexALGmqdwuJxMeta || {},
    alias: indexALGmqdwuJxMeta?.alias || [],
    redirect: indexALGmqdwuJxMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/geo/regions/index.vue").then(m => m.default || m)
  },
  {
    name: inboxJZ4kDNG3GkMeta?.name ?? "inbox___ru",
    path: inboxJZ4kDNG3GkMeta?.path ?? "/inbox",
    meta: inboxJZ4kDNG3GkMeta || {},
    alias: inboxJZ4kDNG3GkMeta?.alias || [],
    redirect: inboxJZ4kDNG3GkMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: indexyqIBGJQToqMeta?.name ?? "index___ru",
    path: indexyqIBGJQToqMeta?.path ?? "/",
    meta: indexyqIBGJQToqMeta || {},
    alias: indexyqIBGJQToqMeta?.alias || [],
    redirect: indexyqIBGJQToqMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTha7ODZrTDMeta?.name ?? "pages-id___ru",
    path: indexTha7ODZrTDMeta?.path ?? "/pages/:id()",
    meta: indexTha7ODZrTDMeta || {},
    alias: indexTha7ODZrTDMeta?.alias || [],
    redirect: indexTha7ODZrTDMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: update0UYLDiSLSJMeta?.name ?? "pages-id-update___ru",
    path: update0UYLDiSLSJMeta?.path ?? "/pages/:id()/update",
    meta: update0UYLDiSLSJMeta || {},
    alias: update0UYLDiSLSJMeta?.alias || [],
    redirect: update0UYLDiSLSJMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createnvThzFOZSSMeta?.name ?? "pages-create___ru",
    path: createnvThzFOZSSMeta?.path ?? "/pages/create",
    meta: createnvThzFOZSSMeta || {},
    alias: createnvThzFOZSSMeta?.alias || [],
    redirect: createnvThzFOZSSMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/create.vue").then(m => m.default || m)
  },
  {
    name: indexKlZ4cKtKCaMeta?.name ?? "pages___ru",
    path: indexKlZ4cKtKCaMeta?.path ?? "/pages",
    meta: indexKlZ4cKtKCaMeta || {},
    alias: indexKlZ4cKtKCaMeta?.alias || [],
    redirect: indexKlZ4cKtKCaMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileI8EZJt5br2Meta?.name ?? undefined,
    path: profileI8EZJt5br2Meta?.path ?? "/profile",
    meta: profileI8EZJt5br2Meta || {},
    alias: profileI8EZJt5br2Meta?.alias || [],
    redirect: profileI8EZJt5br2Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: indexxKoRSq3Hd6Meta?.name ?? "profile___ru",
    path: indexxKoRSq3Hd6Meta?.path ?? "",
    meta: indexxKoRSq3Hd6Meta || {},
    alias: indexxKoRSq3Hd6Meta?.alias || [],
    redirect: indexxKoRSq3Hd6Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: settingsHFxVacfWAQMeta?.name ?? "profile-settings___ru",
    path: settingsHFxVacfWAQMeta?.path ?? "settings",
    meta: settingsHFxVacfWAQMeta || {},
    alias: settingsHFxVacfWAQMeta?.alias || [],
    redirect: settingsHFxVacfWAQMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/profile/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexwwdTcMfKzNMeta?.name ?? "settings___ru",
    path: indexwwdTcMfKzNMeta?.path ?? "/settings",
    meta: indexwwdTcMfKzNMeta || {},
    alias: indexwwdTcMfKzNMeta?.alias || [],
    redirect: indexwwdTcMfKzNMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membersE4GFGQM8GyMeta?.name ?? "settings-members___ru",
    path: membersE4GFGQM8GyMeta?.path ?? "/settings/members",
    meta: membersE4GFGQM8GyMeta || {},
    alias: membersE4GFGQM8GyMeta?.alias || [],
    redirect: membersE4GFGQM8GyMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: notificationsgagROoUMkfMeta?.name ?? "settings-notifications___ru",
    path: notificationsgagROoUMkfMeta?.path ?? "/settings/notifications",
    meta: notificationsgagROoUMkfMeta || {},
    alias: notificationsgagROoUMkfMeta?.alias || [],
    redirect: notificationsgagROoUMkfMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: updateVZGjo9fJECMeta?.name ?? "tags-id-update___ru",
    path: updateVZGjo9fJECMeta?.path ?? "/tags/:id()/update",
    meta: updateVZGjo9fJECMeta || {},
    alias: updateVZGjo9fJECMeta?.alias || [],
    redirect: updateVZGjo9fJECMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createDxDMi6yJDjMeta?.name ?? "tags-create___ru",
    path: createDxDMi6yJDjMeta?.path ?? "/tags/create",
    meta: createDxDMi6yJDjMeta || {},
    alias: createDxDMi6yJDjMeta?.alias || [],
    redirect: createDxDMi6yJDjMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/create.vue").then(m => m.default || m)
  },
  {
    name: indexU1bUdvT9xOMeta?.name ?? "tags___ru",
    path: indexU1bUdvT9xOMeta?.path ?? "/tags",
    meta: indexU1bUdvT9xOMeta || {},
    alias: indexU1bUdvT9xOMeta?.alias || [],
    redirect: indexU1bUdvT9xOMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/tags/index.vue").then(m => m.default || m)
  },
  {
    name: testh61wq6mQAAMeta?.name ?? "test___ru",
    path: testh61wq6mQAAMeta?.path ?? "/test",
    meta: testh61wq6mQAAMeta || {},
    alias: testh61wq6mQAAMeta?.alias || [],
    redirect: testh61wq6mQAAMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/test.vue").then(m => m.default || m)
  },
  {
    name: updatewL4qo59xdrMeta?.name ?? "users-id-update___ru",
    path: updatewL4qo59xdrMeta?.path ?? "/users/:id()/update",
    meta: updatewL4qo59xdrMeta || {},
    alias: updatewL4qo59xdrMeta?.alias || [],
    redirect: updatewL4qo59xdrMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: createhE4P9hY0V0Meta?.name ?? "users-create___ru",
    path: createhE4P9hY0V0Meta?.path ?? "/users/create",
    meta: createhE4P9hY0V0Meta || {},
    alias: createhE4P9hY0V0Meta?.alias || [],
    redirect: createhE4P9hY0V0Meta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexsmYbWxttTuMeta?.name ?? "users___ru",
    path: indexsmYbWxttTuMeta?.path ?? "/users",
    meta: indexsmYbWxttTuMeta || {},
    alias: indexsmYbWxttTuMeta?.alias || [],
    redirect: indexsmYbWxttTuMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexoxLiJGNsBYMeta?.name ?? "villages-id___ru",
    path: indexoxLiJGNsBYMeta?.path ?? "/villages/:id()",
    meta: indexoxLiJGNsBYMeta || {},
    alias: indexoxLiJGNsBYMeta?.alias || [],
    redirect: indexoxLiJGNsBYMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: updatesP9LqS76lmMeta?.name ?? "villages-id-update___ru",
    path: updatesP9LqS76lmMeta?.path ?? "/villages/:id()/update",
    meta: updatesP9LqS76lmMeta || {},
    alias: updatesP9LqS76lmMeta?.alias || [],
    redirect: updatesP9LqS76lmMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/[id]/update.vue").then(m => m.default || m)
  },
  {
    name: create7AuD6UTcqSMeta?.name ?? "villages-create___ru",
    path: create7AuD6UTcqSMeta?.path ?? "/villages/create",
    meta: create7AuD6UTcqSMeta || {},
    alias: create7AuD6UTcqSMeta?.alias || [],
    redirect: create7AuD6UTcqSMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/create.vue").then(m => m.default || m)
  },
  {
    name: indexK4fRuqJBPZMeta?.name ?? "villages___ru",
    path: indexK4fRuqJBPZMeta?.path ?? "/villages",
    meta: indexK4fRuqJBPZMeta || {},
    alias: indexK4fRuqJBPZMeta?.alias || [],
    redirect: indexK4fRuqJBPZMeta?.redirect,
    component: () => import("/var/www/app/www/admin.stage.totdom.com/releases/20240906155510/pages/villages/index.vue").then(m => m.default || m)
  }
]